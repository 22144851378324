/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

:root {
	--blue: #ec7813;
	--light-blue: #207CFF1C;
	--black: #00044B;
	--grey: #797979;
	--light-grey: #89898926;
	--blue-grey: #ACB5CF;
	--light-blue-grey: #ACB5CF80;
	--sky-blue: #D7ECFF;
	--text-secondary: #0000004D;
	--pink: #E1056D;
}

.text-primary {
	color: var(--blue) !important;
}

// .bg-primary { 
// 	background-color: var(--blue) !important;;
// }

.btn-primary {
	background: var(--blue) !important;
}

// .main-menu.menu-light { 
// 	background-color: var(--blue);
// }

// .main-menu.menu-light .navigation { 
// 	background: none;
// }

// .vertical-layout .main-menu .navigation a.active {
//     background: none;
// 	box-shadow: none;
// }

// .main-menu.menu-light .navigation li a { 
// 	color: white;
// }



.divider-v {
	background: #4B2D0099;
	height: auto;
	width: 2px;
	border-radius: 2px;
	margin-left: 25px;
	margin-right: 25px;
}

.screen-title {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;

	color: var(--black);
}

.dashboard-grid-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 5px;
	row-gap: 20px;
}

.dashboard-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px 20px 20px;
	// gap: 28px;	

	margin-bottom: 20px;
	
	width: 100%;

	background: white;
	box-shadow: 0px 0px 30px 0px var(--light-grey);
	border-radius: 40px;
}

.dashboard-card-title {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: var(--grey);
}

.dashboard-card-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border-radius: 60px;
	margin-bottom: 10px;
}

.dashboard-card-label {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #00000080;
}

.dashboard-card-value {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 48px;
	line-height: 57px;
	letter-spacing: -0.015em;
	color: #000000CC;
}

.dashboard-card-total { 
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 8px 20px;
	width: 100%;
	background: var(--light-blue);
	border-radius: 20px;
}

.dashboard-leader-board {
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 15px;
	gap: 12px;
	background-color: white;
	width: 100%;
	min-height: 80vh;
	overflow-y: scroll;
	border: 1px solid var(--light-blue-grey);
	border-radius: 20px;
}

.leader-board-title {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;

	color: black;
}

.leader-board-type {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	// width: 144px;
	// height: 24px;

	background: #F3F3F3;
	border-radius: 30px;

}

.btn-leader-board-type {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 5px 10px;
	gap: 10px;
	background: transparent !important;
	border: none !important;

	// width: 69px;
	// height: 24px;
	border-radius: 30px;
}

.btn-leader-board-type span {
	font-family: 'Rubik' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 14px !important;
	text-align: right !important;

	color: #00000080;
}

.btn-leader-board-type:hover {
	box-shadow: none !important;
}

.btn-leader-board-type.active {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 5px 10px;

	// width: 69px;
	// height: 24px;

	background: var(--blue) !important;
	border-radius: 30px !important;
}

.btn-leader-board-type.active span{
	font-family: 'Rubik' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 14px !important;
	text-align: right !important;

	color: white;
}

.leader-board-sort {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 5px 20px;

	width: 100%;

	background: var(--sky-blue);
	border-radius: 6px;
}

.leader-board-sort-options {
	background-color: transparent !important;
	background: none !important;
	padding-right: 10px;
}

.btn-leader-board-sort {
	background: transparent !important;
	background-color: transparent !important;
	border: none !important;
	padding: 5px 18px !important;
}

.btn-leader-board-sort:focus {
	background: transparent !important;
	background-color: transparent !important;
	border: none !important;
}

.btn-leader-board-sort span {
	font-family: 'Rubik' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 14px !important;

	color: #00000080 !important;
}

.btn-leader-board-sort:hover {
	box-shadow: none !important;
}

.btn-leader-board-sort.active span{
	font-family: 'Rubik' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 14px !important;

	color: var(--blue) !important;
}

.leader-board-list {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100% !important;
}

.leader-board-item {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px 12px !important;
	// gap: 16px;
	border: 1px solid var(--sky-blue) !important;
	border-radius: 10px !important;
	margin-bottom: 20px !important;
}

.leader-board-item-header{
	display: flex;
	width: 100%;
	padding: 12px 0px !important;
}

.leader-board-item-body { 
	width: 100%;
	padding: 0px 0px !important;
}

.leader-board-item-body > .accordion-body { 
	padding: 12px 0px !important;
}

.leader-board-item-header .accordion-button {
	padding: 0px;
}

.leader-board-item-header-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.leader-board-item-header-icon {
	height: 40px;
	width: 40px;
	border-radius: 100%;
	background-color: var(--sky-blue);
}

.leader-board-item-header-value {
	text-align: center;
	font-weight: bolder;
	font-size: 14px;
}

.leader-board-item-header-label {
	text-align: center;
	color: var(--text-secondary);
	font-size: 14px;
}

// Lead Add Screen

.form-container-left {
	overflow-y: auto;
    height: 80vh;
    border-right: 1px dashed var(--blue);
	border-spacing: 10px;
	margin-bottom: 20px;
	order: 0;
}

.form-container-right {
	order: 1;
	margin-bottom: 20px;	
}

.form-nav-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px 10px 0px 0px;
	gap: 8px;
	background: transparent;
}

.form-nav-item-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	background: transparent !important;
}

.form-nav-item {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 10px;
	
	background: white;
	border: 1px solid var(--sky-blue);
	border-radius: 10px;
	margin-left: 5px;
}

.form-nav-item.active > .nav-link {
	font-family: 'Rubik' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;

	/* PT Primary */

	color: var(--blue)  !important;
}

.form-nav-item-container:has(.form-nav-item.active)::before {
	content: "";
	display: inline-block;
	height: 40px;
	border: 2px solid var(--blue);
	border-radius: 10px;
}

.form-title {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 15px 20px;
	gap: 10px;

	background: var(--sky-blue);
	border-radius: 10px;

	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;

	color: var(--blue);

	margin-bottom: 20px;
}

.form-container-left::-webkit-scrollbar {
	width: 10px;
  }
  
  .form-container-left::-webkit-scrollbar-track {
	background:#ccc;
  }
  
  .form-container-left::-webkit-scrollbar-thumb {
	background: #ff6b35; 
	border-radius: 10px;
  }

.rdt_TableHeadRow, th {
    background-color: #fcf6f1!important;
}

.static-card {
	position: fixed;
	top: 1; /* You can adjust the top position as needed */
	left: 1; /* You can adjust the left position as needed */
	// z-index: 1000; /* Ensure it's above other content */
  }

  .fixed-column {
	position: fixed;
	// top: 0;
	// left: 0;
	height: 100%; // Adjust the height as needed /
	overflow: hidden; // Hide any content that exceeds the height /
  }

.scrollable-column {
	overflow-x: auto;
}